import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SectionContext, StepType, SURVEY_SECTION_TYPE_MAP, SurveySectionType, UploadItem } from '@dmv/public/shared/http';
import {
  buildAnswerPrompts,
  buildSectionReview,
  buildSectionReviewFromAnswers,
  buildUploadItem,
  buildUploadItems,
  checkAutoAdvance,
  checkDocTypeChangeEligible,
  confirmationErrorMessage,
} from '@dmv/public/shared/utils';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { concatMap, exhaustMap, map, switchMap } from 'rxjs/operators';
import { NavigationActions } from '../actions';
import * as SurveySectionActions from '../actions/survey-section.actions';
import { SurveySectionSelectors } from '../selectors';

@Injectable()
export class SurveySectionEffects {
  public buildSectionReviewItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.buildSectionReviewItems),
      concatLatestFrom(_action => [
        this._store.select(SurveySectionSelectors.selectSectionUploadItems),
        this._store.select(SurveySectionSelectors.selectSectionQuestions),
      ]),
      map(([_action, uploadItems, sectionQuestions]) => {
        const sectionReview = buildSectionReview(sectionQuestions, uploadItems);

        return SurveySectionActions.updateSectionReview({ sectionReview: sectionReview });
      }),
    ),
  );

  public buildSectionReviewItemsFromAnswers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.buildSectionReviewItemsFromAnswers),
      concatLatestFrom(_action => [
        this._store.select(SurveySectionSelectors.selectSectionUploadItems),
        this._store.select(SurveySectionSelectors.selectSurveyAnswers),
      ]),
      map(([_action, uploadItems, questionAnswers]) => {
        const sectionReview = buildSectionReviewFromAnswers(questionAnswers, uploadItems);

        return SurveySectionActions.updateSectionReview({ sectionReview: sectionReview });
      }),
    ),
  );

  public buildSurveySectionUploadItems$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.buildSurveySectionUploadItems),
      concatLatestFrom(() => [
        this._store.select(SurveySectionSelectors.selectDocumentUploadContext),
        this._store.select(SurveySectionSelectors.selectUsedDocumentTypes),
        this._store.select(SurveySectionSelectors.selectSectionId),
        this._store.select(SurveySectionSelectors.selectSectionUploadItems),
        this._store.select(SurveySectionSelectors.selectSurveySectionStatus),
      ]),
      map(([_, sectionDocumentTypes, usedDocumentTypes, sectionId, existingUploadItems, sectionStatus]) => {
        // NOTE: Do not factor in existing items for building upload documents
        const uploadItems: UploadItem[] = sectionDocumentTypes
          ? buildUploadItems(
              sectionDocumentTypes,
              usedDocumentTypes,
              sectionId as SurveySectionType,
              existingUploadItems,
              sectionStatus === 'complete',
            )
          : [];

        return SurveySectionActions.buildUploadItemsSuccess({
          uploadItems,
        });
      }),
    ),
  );

  public successfulUpload$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.buildUploadItemsSuccess),
      map(_action => {
        return SurveySectionActions.buildSectionReviewItemsFromAnswers();
      }),
    ),
  );

  public changeDocType$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.changeDocType),
      concatLatestFrom(() => [
        this._store.select(SurveySectionSelectors.selectDocumentTypes),
        this._store.select(SurveySectionSelectors.selectSectionUploadItems),
      ]),
      switchMap(([_action, allDocTypes, _existingUploadItems]) => {
        const newDocType = allDocTypes.find(
          doc => doc.id === _action.item.details[0].classificationScoreDocAiResults[0].classifiedDocumentTypeId,
        );
        if (!newDocType) {
          console.warn('Warning: Cannot update to new doc type because it was not found.');

          return EMPTY;
        }

        const newUploadItem = buildUploadItem(newDocType);

        return [
          SurveySectionActions.replaceUploadItem({
            newUploadItem: newUploadItem,
            oldUploadItem: _action.item,
          }),
          SurveySectionActions.updateDocChangeRequiredDocIds({
            newUploadItem: newUploadItem,
            oldUploadItem: _action.item,
          }),
          SurveySectionActions.updateDocChangeQuestionAnswers({
            newUploadItem: newUploadItem,
            oldUploadItem: _action.item,
          }),
        ];
      }),
    ),
  );

  public submitTransactionSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.submitTransactionSuccess),
      map(() =>
        NavigationActions.navigateToTransactionPage({
          urlFragments: [SurveySectionType.CONFIRMATION],
        }),
      ),
    ),
  );

  public updateSurveyState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.updateSurveyWrapperState, SurveySectionActions.updateSurveyStateQuestion),
      map(() => {
        return SurveySectionActions.buildSurveySectionUploadItems();
      }),
    ),
  );

  public checkAutoProceed$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SurveySectionActions.checkAutoProceed),
      concatLatestFrom(_action => [this._store.select(SurveySectionSelectors.selectSectionQuestions)]),
      map(([action, questions]) => {
        const activeStep: StepType = checkAutoAdvance(questions, action.isReset, 'survey', 'review');
        const func = _ => ({
          errorPage: null,
          nextStep: activeStep,
          question: null,
        });

        return SurveySectionActions.determineSectionContext({ func, functionContext: {} });
      }),
    );
  });

  public advancePastSurvey$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SurveySectionActions.updateSurveyWrapperState),
      concatLatestFrom(_action => [this._store.select(SurveySectionSelectors.selectSectionQuestions)]),
      switchMap(([_action, questions]) => {
        const activeStep = checkAutoAdvance(questions, false, 'document_upload', 'review');

        return [
          SurveySectionActions.setActiveStep({
            activeStep,
          }),
        ];
      }),
    );
  });

  public continueSurveySection$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.continueSurveySection),
      concatLatestFrom(_action => [
        this._store.select(SurveySectionSelectors.selectActiveStep),
        this._store.select(SurveySectionSelectors.selectSurveySection),
        this._store.select(SurveySectionSelectors.selectCurrentSurveyEntity),
        this._store.select(SurveySectionSelectors.selectSectionIsReviewed),
      ]),
      exhaustMap(([action, activeStep, section, sectionEntity, isReviewed]) => {
        // Some sections do not have questions.  Do not expect this reference to be truthy.
        const currentQuestion = sectionEntity.surveyWrapperState.questions[0];
        // Intentional explicit return statements.
        // This effect needs to return actions in order based on the current step.

        // Validate and display errors
        if (activeStep === 'survey' && currentQuestion.answerPrompts.length > 0 && currentQuestion.answers.length === 0) {
          return of(
            SurveySectionActions.displayErrors({
              display: true,
              errors: [currentQuestion.type !== 'text' ? 'Please make a selection' : 'Please input your answer'],
            }),
          );
        } else if (
          activeStep === 'survey' &&
          !currentQuestion.pointsThreshold &&
          currentQuestion.minPoints != null &&
          currentQuestion.minPoints > 0 &&
          currentQuestion.totalPoints < currentQuestion.minPoints
        ) {
          return of(SurveySectionActions.displayErrors({ display: true, errors: ['Please make a valid selection'] }));
        } else if (
          activeStep === 'survey' &&
          currentQuestion.pointsThreshold &&
          currentQuestion.totalPoints < currentQuestion.pointsThreshold
        ) {
          return of(
            SurveySectionActions.displayErrors({
              display: true,
              errors: [
                `You need a minimum of ${currentQuestion.pointsThreshold} name points. Please choose additional documents for ${currentQuestion.name}.`,
              ],
            }),
          );
        } else if (activeStep === 'document_upload' && sectionEntity.uploadItems.some(upload => !upload.uploaded)) {
          const errors: string[] = [];
          sectionEntity.uploadItems
            .filter(uploadItem => !uploadItem.uploaded)
            .map(item => item.details.forEach(detail => !detail.uploaded && errors.push(`Please upload your ${detail.subTitle}`)));

          return of(SurveySectionActions.displayErrors({ display: true, errors }));
        } else if (activeStep === 'review' && !isReviewed) {
          return of(SurveySectionActions.displayErrors({ display: true, errors: [confirmationErrorMessage] }));
        }

        // Handle function
        if (action.func) {
          return of(SurveySectionActions.determineSectionContext({ func: action.func, functionContext: action.functionContext }));
        }

        // Handle submit action
        if (action.submitAction) {
          return of(SurveySectionActions.setLoading({ loading: true }), action.submitAction);
        }

        // Navigate to next section
        return of(
          NavigationActions.navigateToNextIncompleteSection({
            sectionId: section.id as SurveySectionType,
          }),
        );
      }),
    ),
  );

  public checkDocTypeChange$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(SurveySectionActions.checkDocChange),
      concatLatestFrom(_action => this._store.select(SurveySectionSelectors.selectCurrentSurveyEntity)),
      switchMap(([_action, section]) => {
        const isDocEligible = checkDocTypeChangeEligible(section.surveyWrapperState.questions, _action.detail);

        return [
          SurveySectionActions.setDocChange({
            detail: _action.detail,
            docTypeChangeEligible: isDocEligible,
          }),
        ];
      }),
    );
  });

  public determineSectionContext$ = createEffect(() =>
    this._actions$.pipe(
      ofType(SurveySectionActions.determineSectionContext),
      concatLatestFrom(_action => [
        this._store.select(SurveySectionSelectors.selectActiveStep),
        this._store.select(SurveySectionSelectors.selectSurveySection),
        this._store.select(SurveySectionSelectors.selectCurrentSurveyEntity),
        this._store.select(SurveySectionSelectors.selectDocumentTypes),
        this._store.select(SurveySectionSelectors.selectUsedDocumentTypes),
        this._store.select(SurveySectionSelectors.selectSurveySectionQuestionIndex),
      ]),
      concatMap(([action, activeStep, section, sectionEntity, documentTypes, usedDocumentTypes, questionIndex]) => {
        const currentQuestion = sectionEntity.surveyWrapperState.questions[0];
        const answerId =
          currentQuestion?.answerPrompts.find(answerPrompt => currentQuestion.answers.includes(answerPrompt.value))?.id || null;

        const sectionContext: SectionContext = action.func(
          answerId,
          activeStep,
          sectionEntity.answers,
          currentQuestion?.id || null,
          section.id as SurveySectionType,
          action.functionContext,
        );

        let nextActions: Actions;
        // Handle section context
        if (sectionContext && sectionContext.errorPage) {
          // Navigate to error page
          nextActions = of(NavigationActions.navigateToTransactionPage({ urlFragments: [section.id, sectionContext.errorPage] }));
        } else if (sectionContext && sectionContext.nextPage) {
          nextActions = of(NavigationActions.navigateToTransactionPage({ urlFragments: [sectionContext.nextPage] }));
        } else if (sectionContext && sectionContext.nextStep) {
          // Build survey section and set active step
          nextActions = of(
            SurveySectionActions.updateSurveyStateRequiredDocumentIds({}),
            SurveySectionActions.buildSurveySectionUploadItems(),
            SurveySectionActions.setActiveStep({ activeStep: sectionContext.nextStep }),
            currentQuestion && answerId
              ? SurveySectionActions.updateAnswers({
                  answerId,
                  answers: currentQuestion.answers,
                  questionId: currentQuestion.id,
                  questionPrompt: currentQuestion.question,
                })
              : { type: '[noop]' },
          );
        } else {
          // Update survey state and answers
          // If next question has no answerPrompts, treat as dynamic answer prompt question.
          const newQuestionPrompts =
            sectionContext.question.answerPrompts && sectionContext.question.answerPrompts.length === 0
              ? buildAnswerPrompts(documentTypes, usedDocumentTypes, SURVEY_SECTION_TYPE_MAP[sectionEntity.sectionId])
              : sectionContext.question.answerPrompts;
          nextActions = of(
            SurveySectionActions.updateSurveyStateQuestion({
              answerPrompts: newQuestionPrompts,
              surveyEvent: {
                questionIndex,
                questions: [sectionContext.question],
              },
            }),
            SurveySectionActions.updateAnswers({
              answerId,
              answers: currentQuestion.answers,
              questionId: currentQuestion.id,
              questionPrompt: currentQuestion.question,
            }),
          );
        }

        return nextActions;
      }),
    ),
  );

  constructor(private readonly _actions$: Actions, private readonly _router: Router, private readonly _store: Store) {}
}
