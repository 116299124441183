import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UploadStatus } from '@dmv/common';
import { DocumentService, PdfService, UploadPanelService } from '@dmv/core';
import {
  DocAiNotificationError,
  DocumentSide,
  FileUploadStaging,
  SharedApiService,
  UploadDocumentDetail,
  UploadItem,
  UploadItemResult,
} from '@dmv/public/shared/http';
import { FeatureFlagService } from '@libs/feature-flag';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { MULTI_UPLOAD_PANEL_CONFIGURATION, MultiUploadPanelConfiguration } from '../multi-upload-panel.types';
import { UploadPanelModalComponent } from '../upload-panel-modal/upload-panel-modal.component';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 'dmv-shared-feature-upload-panel',
  styleUrls: ['./upload-panel.component.scss'],
  templateUrl: './upload-panel.component.html',
})
export class UploadPanelComponent implements OnInit {
  /**
   * This is the transactionObject's id value; not the transactionId string value
   */
  @Input() public transactionId;
  @Input() public transactionType;
  @Input() public required: boolean;
  @Input() public uploadItem: UploadItem; //main object for this component, has 1-2 details
  @Input() public validDocTypes: string[] = ['jpg', 'pdf', 'png', 'jpeg'];
  @Input() public newUpload = true;
  @Input() public itemIndex = 0;
  @Input() public itemIndexBias = 0;
  @Input() public transactionData: Map<string, string>;
  @Input() public docaiPollDurationSeconds = 10;

  @Output() public readonly uploadItemLoading = new EventEmitter<boolean>();
  @Output() public readonly checkDocTypeChangeEligible = new EventEmitter<UploadDocumentDetail>();
  @Output() public readonly docTypeChangeClicked = new EventEmitter<UploadItem>();

  @Output() public readonly uploadItemChange = new EventEmitter<UploadItem>();
  @Output() public readonly uploadDetailReuploaded = new EventEmitter<UploadDocumentDetail>();
  @Output() public readonly itemUploaded = new EventEmitter<UploadDocumentDetail>();

  @ViewChild('fileInput') public fileInput: ElementRef;
  @ViewChild('accordion') public ngbAccordion: NgbAccordionDirective;

  public showFrontAlert = true;
  public showBackAlert = true;

  public docAIFeatureFlagEnabled = false;
  public docTypeChangeFeatureFlagEnabled = false;
  public isNotUniqueFile = false;
  public selectedDetail: UploadDocumentDetail; //most recently selected (browse clicked) detail
  public completed = false;
  public expanded = true;
  public maxFileSize = 15728640;
  public length = 10;
  public uploadedFileNames: File[] = [];
  public curFileName = '';
  public uploadAttempts = 1;
  public activeIds: string[] = [];
  public isBackUploadDisabled = false;

  private _detailsLoading: UploadDocumentDetail[] = [];

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _uploadDocumentService: DocumentService,
    private readonly _dialog: MatDialog,
    private readonly _pdfService: PdfService,
    @Inject(MULTI_UPLOAD_PANEL_CONFIGURATION) private readonly _multiUploadPanelConfiguration: MultiUploadPanelConfiguration,
    private readonly _sharedApiService: SharedApiService,
    private readonly _featureFlagService: FeatureFlagService,
    private readonly _uploadPanelService: UploadPanelService,
  ) {}

  public ngOnInit(): void {
    this.docAIFeatureFlagEnabled = this._featureFlagService.isFeatureFlagEnabledFromArray([
      'public-doc-ai-classification',
      'public-doc-ai-extraction',
      'public-doc-ai-quality',
    ]);

    this.docTypeChangeFeatureFlagEnabled = this._featureFlagService.isFeatureFlagEnabled('public-doc-ai-change-doc');

    if (this.isValid(true) || this.uploadItem.uploadedDocumentId) {
      this.completed = true;
    }
    this.activeIds.push(`upload-item-${this.itemIndex}`);

    // we do not classify any back image so we only need to check the first detail
    if (this.uploadItem && this.uploadItem.details[0]?.reUploadReq) {
      this.selectedDetail = this.uploadItem.details[0];

      //need to set detail as loading before we upload, this will emit to parent components
      this.setDetailLoading(this.selectedDetail);
      this.isNotUniqueFile = false;

      // this returns a Promise<void> that can be safely ignored
      this._beginUpload(this.selectedDetail.files).then();
    }

    this.isBackUploadDisabled = this.uploadItem?.sequentialUpload ?? false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this._detailsLoading.length > 0 && changes?.uploadItem?.previousValue && changes?.uploadItem?.currentValue) {
      const newDetails = [];

      changes?.uploadItem?.currentValue?.details.forEach((currentDetail: UploadDocumentDetail) => {
        const oldLoadingDetail = this._detailsLoading.find(loadingDetail => currentDetail.id === loadingDetail.id);
        if (oldLoadingDetail) {
          newDetails.push(oldLoadingDetail);
        } else {
          newDetails.push(currentDetail);
        }
      });
      this.uploadItem.details = newDetails;
    }

    if (this.uploadItem?.sequentialUpload) {
      const currentUploadItem = changes.uploadItem.currentValue;
      currentUploadItem.details?.forEach((currentDetail: UploadDocumentDetail) => {
        if (currentDetail.side === DocumentSide.FRONT) {
          this.isBackUploadDisabled = !currentDetail.uploaded;
        }
      });
    }
  }

  public onUploadToggle($event) {
    this.expanded = $event.nextState;
  }

  public browseButtonClicked(detail: UploadDocumentDetail) {
    this.selectedDetail = detail;
    this.fileInput.nativeElement.value = null;
    this.isNotUniqueFile = false;
    this.fileInput.nativeElement.click();

    this.setDetailLoading(this.selectedDetail);
  }

  public setDetailLoading(detail: UploadDocumentDetail) {
    //this logic stops the parent components from advancing to review page while docAI checks are occurring
    this._detailsLoading.push(detail);
    //need to emit here to let the containing component know we are currently uploading
    this.uploadItemLoading.emit(this._detailsLoading.length > 0);
  }

  public docTypeChangeButtonClicked(item: UploadItem) {
    this.docTypeChangeClicked.emit(new UploadItem(item));
  }

  public async cancelFile() {
    this.removeDetailLoading(this.selectedDetail);
    this.uploadItemLoading.emit(this._detailsLoading.length > 0);
  }

  public async browseFile($event) {
    const files: File[] = Array.from($event?.target?.files);
    this.isNotUniqueFile = !this._uploadPanelService.isUniqueFile(files, this.uploadItem);
    if (this.isNotUniqueFile) {
      //remove file from array
      this.removeDetailLoading(this.selectedDetail);

      return;
    }

    this._beginUpload(files);
  }

  public getAlertType(item: UploadDocumentDetail) {
    return this._uploadPanelService.getAlertType(item);
  }

  public displayDocAIErrorItem(item: UploadItem) {
    return this._uploadPanelService.displayDocAIErrorItem(item);
  }

  public displayDocAIErrorDetail(detail: UploadDocumentDetail) {
    return this._uploadPanelService.displayDocAIErrorDetail(detail);
  }

  public closeAlert(item: UploadDocumentDetail) {
    item.status = null;
  }

  public getUploadTag(item: UploadDocumentDetail): string {
    return this._uploadPanelService.getUploadTag(item, this.uploadItem);
  }

  public showMultiple() {
    if (this.uploadItem) {
      return !this.uploadItem.singleFileChoose;
    }

    return true;
  }

  public markAsCompleted() {
    this.completed = true;
  }

  public getNotificationError(detail: UploadDocumentDetail): DocAiNotificationError {
    return this._uploadPanelService.getNotificationError(detail);
  }

  public openPreviewModal(uploadDetails: UploadDocumentDetail[]): MatDialogRef<UploadPanelModalComponent> {
    return this._dialog.open(UploadPanelModalComponent, {
      autoFocus: false,
      data: {
        frontImage: uploadDetails[0].placeHolderUrl,
        title: this.uploadItem.title,
      },
      width: '900px',
    });
  }

  public getItemErrorMsg(item: UploadDocumentDetail): string {
    return this._uploadPanelService.getItemErrorMsg(item, this.uploadedFileNames, this.curFileName);
  }

  public isValid(notSetStatus?: boolean) {
    let valid = true;
    if (this.uploadItem && this.uploadItem.details) {
      this.uploadItem.details.forEach(detail => {
        if (detail.required && (!detail.files || detail.status !== UploadStatus.SUCCESS)) {
          valid = false;
          if (!notSetStatus) {
            detail.status = UploadStatus.REQUIRED;
          }
        }
      });
    }

    return valid;
  }

  public docAiNotificationClosed(detail: UploadDocumentDetail): void {
    this.switchShowAlert(false, detail.subTitle.includes('Back') ? 'back' : 'front');
  }

  public switchShowAlert(newState: boolean, side: 'front' | 'back'): void {
    if (side === 'front') {
      this.showFrontAlert = newState;
    }
    if (side === 'back') {
      this.showBackAlert = newState;
    }
  }

  public removeDetailLoading(detail: UploadDocumentDetail): void {
    const detailLoadingIndex = this._detailsLoading.findIndex(loadingDetail => loadingDetail.id === detail.id);
    this._detailsLoading = [...this._detailsLoading.slice(0, detailLoadingIndex), ...this._detailsLoading.slice(detailLoadingIndex + 1)];
  }

  private async _beginUpload(files: File[]) {
    const isReUpload = this.selectedDetail.uploaded;

    this.uploadItem.uploaded = false;
    this.selectedDetail.docTypeChangeEligible = false;
    this.selectedDetail.uploaded = false;
    this.selectedDetail.loading = true;

    if (isReUpload) {
      this.uploadDetailReuploaded.emit(new UploadDocumentDetail(this.selectedDetail));
    }

    this.switchShowAlert(true, this.selectedDetail.subTitle.includes('Back') ? 'back' : 'front');

    const valid = await this._isValidFiles(files);
    if (!valid) {
      this.selectedDetail.loading = false;
      this.selectedDetail.files = [];
      this.selectedDetail.imageSrc = null;
      this._changeDetectorRef.markForCheck();

      return;
    }
    this._setSelectedFiles(files);

    if (this._pdfService.isValidInput(this.selectedDetail.files)) {
      this.curFileName = null;

      if (this.uploadItem.multiFileUpload && files.length > 1) {
        this.uploadedFileNames = [this.selectedDetail.files[0]];
        await this._stageMultiFiles(this.selectedDetail);
      } else {
        this.uploadedFileNames = [...this.selectedDetail.files];
        await this._stageFiles(this.selectedDetail);
      }
    } else {
      this.selectedDetail.status = UploadStatus.INVALID;
    }
  }

  private _setSelectedFiles(files: File[]) {
    if (files && files.length > 0) {
      // For some browsers, FireFox for example, files is a reference rather than a cloned object
      // Therefore, need to clone files to resolve same front and back image issues
      this.selectedDetail.files = this._cloneFiles(files);
      this.selectedDetail.imageSrc = [];
      this.selectedDetail.imageSrc.length = files.length;

      files.forEach((file, index) => {
        if (this._pdfService.isImage(files[index].name)) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            this.selectedDetail.imageSrc[index] = reader.result as string;
          };
        } else {
          this.selectedDetail.imageSrc[index] = this._uploadDocumentService.SAMPLE_PDF;
        }
        this._changeDetectorRef.markForCheck();
      });
    }
  }

  private _cloneFiles(files: File[]): File[] {
    if (!files || files.length === 0) {
      return [];
    }
    const cloneFiles = [];
    for (const item of files) {
      cloneFiles.push(new File([item], item.name, { type: item.type }));
    }

    return cloneFiles;
  }

  private async _stageMultiFiles(detail: UploadDocumentDetail) {
    const isValid = await this._isValidFiles(detail.files);
    if (!isValid) {
      return;
    }

    const files = detail.files;
    const subtitle = detail.subTitle;
    const sideString = subtitle.includes('Back') ? 'back' : 'front';
    detail.status = UploadStatus.UPLOADING;

    this._uploadPanelService.clearNewUploadResponse(this.uploadItem, detail, sideString);

    if (!this.transactionData || this.transactionData.size === 0) {
      this.transactionData = new Map<string, string>();
    }
    this.transactionData.set('transaction_type', this.transactionType);
    const transactionData = Object.fromEntries(this.transactionData);

    const documentTypeId = this.uploadItem.documentTypeId ? this.uploadItem.documentTypeId : detail.documentType;

    const formData = new FormData();
    const documentPayload = {
      documentTypeId,
      parentDocumentTypeId: this.uploadItem.parentDocumentTypeId,
      side: sideString,
      transactionData,
      transactionId: this.transactionId,
      transactionType: this.transactionType,
    };

    const metadataFile = new File([JSON.stringify(documentPayload)], 'metadata', {
      type: 'application/json',
    });
    formData.append('metadata', metadataFile);

    for (const file of files) {
      formData.append('file', file);
    }

    const stageApi = this._sharedApiService.postStageInPartsOriginalDocuments.bind(this._sharedApiService);

    this._stageAPI(stageApi, formData)
      .pipe(take(1))
      .subscribe(uploadItemResult => {
        this._completeUpload(detail, uploadItemResult);
      });
  }

  private async _stageFiles(detail: UploadDocumentDetail) {
    const files = detail.files;
    const subtitle = detail.subTitle;
    const sideString = subtitle.includes('Back') ? 'back' : 'front';
    detail.status = UploadStatus.UPLOADING;

    if (!this.transactionData || this.transactionData.size === 0) {
      this.transactionData = new Map<string, string>();
    }
    this.transactionData.set('transaction_type', this.transactionType);
    const transactionData = Object.fromEntries(this.transactionData);

    const stageApi =
      this._multiUploadPanelConfiguration.stagingType === 'staging-documents'
        ? this._sharedApiService.postStagingOriginalDocuments.bind(this._sharedApiService)
        : this._sharedApiService.postStagingIds.bind(this._sharedApiService);

    this._uploadPanelService.clearNewUploadResponse(this.uploadItem, detail, sideString);

    this.isBackUploadDisabled = this.uploadItem.sequentialUpload && detail.side === DocumentSide.FRONT;

    const isValid = await this._isValidFiles(files);
    if (isValid) {
      for (const file of files) {
        const formData = new FormData();
        const documentPayload = {
          documentTypeId: this.uploadItem.documentTypeId ? this.uploadItem.documentTypeId : detail.documentType,
          parentDocumentTypeId: this.uploadItem.parentDocumentTypeId,
          side: sideString,
          transactionData,
          transactionId: this.transactionId,
          transactionType: this.transactionType,
        };

        const metadataFile = new File([JSON.stringify(documentPayload)], 'metadata', {
          type: 'application/json',
        });
        formData.append('file', file);
        formData.append('metadata', metadataFile);

        this._stageAPI(stageApi, formData)
          .pipe(take(1))
          .subscribe(uploadItemResult => {
            this._completeUpload(detail, uploadItemResult);
          });
      }
    }
  }

  private _stageAPI(stageApi: any, formData: FormData): Observable<UploadItemResult> {
    return stageApi(formData).pipe(
      take(1),
      switchMap((stagingResponse: FileUploadStaging) => {
        this.fileInput.nativeElement.value = null;
        if (this.docAIFeatureFlagEnabled && stagingResponse.docAiResultsId && stagingResponse.docAiProcessors.length > 0) {
          return this._uploadPanelService
            .pollForData(stagingResponse.docAiProcessors, stagingResponse.docAiResultsId, this.docaiPollDurationSeconds)
            .pipe(
              map(res => {
                return { docAiResults: res, stagingResponse };
              }),
            );
        } else {
          return of({ stagingResponse });
        }
      }),
      catchError(error => {
        this.selectedDetail.status = UploadStatus.FAILED;
        this.fileInput.nativeElement.value = null;
        this._changeDetectorRef.markForCheck();

        return of(error);
      }),
    );
  }

  private _completeUpload(detail: UploadDocumentDetail, uploadItemResult: UploadItemResult) {
    this._uploadPanelService.processUploadItemResult(uploadItemResult, this.uploadItem);

    this.itemUploaded.emit(detail);
    this.uploadItemChange.emit(new UploadItem(this.uploadItem));
    this.removeDetailLoading(detail);
    this.uploadItemLoading.emit(this._detailsLoading.length > 0);
    this.checkDocTypeChangeEligible.emit(new UploadDocumentDetail(detail));
    this._changeDetectorRef.markForCheck();
    this.markAsCompleted();
  }

  private async _isValidFiles(files: File[]) {
    // Validate File length
    if (!files || files.length === 0) {
      return false;
    } else if (files.length > this.length) {
      this.selectedDetail.status = UploadStatus.FILELENGTH;

      return false;
    }
    // Validate file size
    let fileSizeSum = 0;
    for (const file of files) {
      fileSizeSum += file.size;
      if (file.size === 0) {
        this.selectedDetail.status = UploadStatus.MINFILESIZE;

        return false;
      }
    }
    if (fileSizeSum > this.maxFileSize) {
      this.selectedDetail.status = UploadStatus.FILESIZE;

      return false;
    }

    let valid = true;
    for (const file of files) {
      const isFile = await this._isValidFile(file);
      if (!isFile) {
        valid = false;
      }
    }

    return valid;
  }

  private async _isValidFile(file: File) {
    return this._uploadPanelService.isValidFile(file, this.validDocTypes, this.selectedDetail);
  }
}
