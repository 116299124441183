import { Injectable } from '@angular/core';
import { TransactionType } from '@dmv/core';
import { SurveySectionType } from '@dmv/public/shared/http';
import { Store } from '@ngrx/store';
import * as ModalActions from '../actions/modal.actions';

@Injectable({
  providedIn: 'root',
})
export class ModalFacade {
  constructor(private readonly _store: Store) {}

  public showRestartSectionModal(sectionId: SurveySectionType): void {
    return this._store.dispatch(ModalActions.showRestartSectionModal({ sectionId }));
  }

  public showLeaveSectionModal(sectionId: SurveySectionType | string): void {
    return this._store.dispatch(ModalActions.showLeaveSectionModal({ sectionId }));
  }
  public showSubmitApplicationDialog(showCounties: boolean, transactionType?: TransactionType): void {
    return this._store.dispatch(ModalActions.showSubmitApplicationDialog({ showCounties, transactionType }));
  }
}
