import { TransactionType } from '@dmv/core';
import { SurveySectionType } from '@dmv/public/shared/http';
import { createAction, props } from '@ngrx/store';

export const showCompletionDialog = createAction(
  '[Modal] Show Dialog After All Steps Completed',
  props<{ showCounties: boolean; transactionType?: TransactionType }>(),
);
export const showLeaveSectionModal = createAction('[Modal] Show Leave Section Modal', props<{ sectionId: SurveySectionType | string }>());
export const showRestartSectionModal = createAction('[Modal] Show Restart Section Modal', props<{ sectionId: SurveySectionType }>());
export const showSubmitApplicationDialog = createAction(
  '[Modal] Show Dialog On Submit Click',
  props<{ showCounties: boolean; transactionType?: TransactionType }>(),
);
export const submitTransactionForReview = createAction(
  '[Modal] Submit Transaction For Review',
  props<{ countyId: number; isPrescreen: boolean }>(),
);
