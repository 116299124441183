import {
  DocumentTypes,
  QuestionImpl,
  Section,
  StepType,
  SurveySectionState,
  UploadDocumentDetail,
  UploadItem,
  UsedDocumentTypes,
} from '@dmv/public/shared/http';
import { confirmationErrorMessage, createNewUploadItems, getRequiredDocumentIds } from '@dmv/public/shared/utils';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as SurveySectionActions from '../actions/survey-section.actions';

export const SURVEY_SECTION_FEATURE_KEY = 'surveySection';

export interface SurveySection extends EntityState<SurveySectionState> {
  activeStep: StepType;
  loading: boolean;
  transactionUri?: string;
  section: Section | null;
  documentTypes?: DocumentTypes[];
  uploadItems?: UploadItem[];
  usedDocumentTypes?: UsedDocumentTypes[] | null;
}

export const surveySectionAdapter: EntityAdapter<SurveySectionState> = createEntityAdapter<SurveySectionState>({
  selectId: (surveySection: SurveySectionState) => surveySection.sectionId,
});

export const initialSurveySectionState: SurveySection = surveySectionAdapter.getInitialState({
  activeStep: 'survey',
  loading: false,
  section: null,
});

const workflowReducer = createReducer(
  initialSurveySectionState,

  on(SurveySectionActions.resetSections, _state => ({ ...initialSurveySectionState })),

  on(SurveySectionActions.setSurveySectionState, (state, { surveyState, section }) =>
    surveySectionAdapter.setOne({ ...surveyState }, { ...state, section }),
  ),

  on(SurveySectionActions.buildUploadItemsSuccess, (state, { uploadItems }) => {
    return surveySectionAdapter.updateOne({ changes: { uploadItems }, id: state.section.id }, { ...state, loading: false });
  }),

  on(SurveySectionActions.initializeSectionSuccess, (state, { activeStep, newState, section, usedDocumentTypes, documentTypes }) => {
    return surveySectionAdapter.setOne(
      { ...newState },
      { ...state, activeStep: activeStep ? activeStep : 'survey', documentTypes, loading: false, section, usedDocumentTypes },
    );
  }),

  on(SurveySectionActions.markSectionReviewed, (state, { isReviewed }) => {
    return surveySectionAdapter.updateOne(
      {
        changes: { displayErrors: { display: !isReviewed, errors: isReviewed ? [] : [confirmationErrorMessage] }, isReviewed },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.sectionMarkDirty, (state, { isDirty }) => {
    return {
      ...state,
      section: {
        ...state.section,
        isDirty,
      },
    };
  }),

  on(SurveySectionActions.setActiveStep, (state, { activeStep }) => {
    return {
      ...state,
      activeStep,
    };
  }),

  on(SurveySectionActions.updateDocumentTypes, (state, { documentTypes }) => {
    return {
      ...state,
      documentTypes,
    };
  }),

  on(SurveySectionActions.updateSectionReview, (state, { sectionReview }) => {
    return surveySectionAdapter.updateOne(
      {
        changes: { displayErrors: { display: false, errors: [] }, sectionReview },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.updateSurveyQuestionIndex, (state, { questionIndex, clearRequiredDocumentIds }) => {
    const requiredDocumentIds = clearRequiredDocumentIds ? [] : state.entities[state.section.id].surveyWrapperState.requiredDocumentIds;
    const section = {
      ...state.entities[state.section.id],
      displayErrors: { display: false, errors: [] },
      surveyWrapperState: {
        ...state.entities[state.section.id].surveyWrapperState,
        questionIndex,
        requiredDocumentIds,
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...section },
        id: section.sectionId,
      },
      state,
    );
  }),

  on(SurveySectionActions.displayErrors, (state, { display, errors }) => {
    return surveySectionAdapter.updateOne(
      {
        changes: { displayErrors: { display, errors } },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.previousQuestionIndex, state => {
    return surveySectionAdapter.updateOne(
      {
        changes: {
          surveyWrapperState: {
            ...state.entities[state.section.id].surveyWrapperState,
            questionIndex: state.entities[state.section.id].surveyWrapperState.questionIndex - 1 || 0,
          },
          uploadItems: [],
        },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.updateAnswers, (state, { answerId, answers, questionId, questionPrompt }) => {
    return surveySectionAdapter.updateOne(
      {
        changes: {
          answers: {
            ...state.entities[state.section.id].answers,
            [questionId]: { answerId, answers, questionPrompt },
          },
        },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.uploadDetailReuploaded, (state, { detail }) => {
    const uploads = state.entities[state.section.id]?.uploadItems;
    const uploadItemIndex = uploads.findIndex(item => item.documentTypeId === detail.documentTypeId);
    const uploadItem = uploads[uploadItemIndex];
    const detailIndex = uploadItem.details.findIndex(findDetail => findDetail.id === detail.id);
    const details = [
      ...uploadItem.details.slice(0, detailIndex),
      { ...detail },
      ...uploadItem.details.slice(detailIndex + 1, uploadItem.details.length),
    ];
    const uploadItems = [
      ...uploads.slice(0, uploadItemIndex),
      { ...uploadItem, details, uploaded: false },
      ...uploads.slice(uploadItemIndex + 1, uploads.length),
    ];

    return surveySectionAdapter.updateOne(
      {
        changes: {
          uploadItems,
        },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.updateSurveyStateRequiredDocumentIds, (state, { ids }) => {
    const surveyWrapperState = state.entities[state.section.id].surveyWrapperState;
    const surveyState = {
      displayErrors: { display: false, errors: [] },
      surveyWrapperState: {
        ...surveyWrapperState,
        requiredDocumentIds: [
          ...(ids || surveyWrapperState.requiredDocumentIds).filter(docId => !surveyWrapperState.tempRequiredDocumentIds.includes(docId)),
          ...surveyWrapperState.tempRequiredDocumentIds,
        ],
        tempRequiredDocumentIds: [],
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: surveyState,
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.updateSurveyQuestionAnswers, (state, { questionIndex }) => {
    const questions = state.entities[state.section.id].surveyWrapperState.questions;
    const sliceToUpdate = questions.slice(questionIndex + 1, questions.length);
    const updatedQuestions = [
      ...questions.slice(0, questionIndex + 1),
      ...sliceToUpdate.map(question => {
        return { ...new QuestionImpl(question), answers: [] };
      }),
    ];

    const surveyState = {
      displayErrors: { display: false, errors: [] },
      surveyWrapperState: {
        ...state.entities[state.section.id].surveyWrapperState,
        questions: updatedQuestions,
        requiredDocumentIds: getRequiredDocumentIds(updatedQuestions),
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: surveyState,
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.setDocChange, (state, { detail, docTypeChangeEligible }) => {
    const uploadItems = state.entities[state.section.id].uploadItems;
    const uploadItemIndex = uploadItems.findIndex(item => item.documentTypeId === detail.documentTypeId);
    const details = uploadItems[uploadItemIndex].details;
    const detailIndex = details.findIndex(checkDetail => checkDetail.id === detail.id);

    const updatedDetails = [
      ...details.slice(0, detailIndex),
      { ...new UploadDocumentDetail(details[detailIndex]), docTypeChangeEligible },
      ...details.slice(detailIndex + 1, details.length),
    ];

    const updatedUploadItems = [
      ...uploadItems.slice(0, uploadItemIndex),
      { ...new UploadItem(uploadItems[uploadItemIndex]), details: updatedDetails },
      ...uploadItems.slice(uploadItemIndex + 1, uploadItems.length),
    ];

    const surveyState = {
      uploadItems: updatedUploadItems,
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...surveyState },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.updateSurveyStateQuestion, (state, { answerPrompts, surveyEvent }) => {
    const surveyWrapperState = state.entities[state.section.id].surveyWrapperState;
    const tempRequiredDocumentIds = surveyWrapperState.tempRequiredDocumentIds || [];
    let questions = surveyWrapperState.questions;
    // Some sections may pass an event where the index is not 0.
    // We will replace just the question being edited.
    const updatedQuestion = surveyEvent.questions[surveyEvent.questions.length === 1 ? 0 : surveyEvent.questionIndex];
    questions = [
      ...questions.slice(0, surveyEvent.questionIndex),
      { ...updatedQuestion, answerPrompts: answerPrompts ? answerPrompts : updatedQuestion.answerPrompts },
      ...questions.slice(surveyEvent.questionIndex + 1),
    ];
    // Update the survey state. Immutably updates questions via slice.
    // Take the zeroth through the question index (exclusive), put in the updated question and then the rest.
    const surveyState = {
      displayErrors: { display: false, errors: [] },
      surveyWrapperState: {
        ...surveyWrapperState,
        questions,
        requiredDocumentIds: [
          ...surveyWrapperState.requiredDocumentIds.filter(docId => !tempRequiredDocumentIds.includes(docId)),
          ...tempRequiredDocumentIds,
        ],
        tempRequiredDocumentIds: [],
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...surveyState },
        id: state.section.id,
      },
      { ...state, loading: false },
    );
  }),

  on(SurveySectionActions.updateSurveyStateQuestionAnswer, (state, { surveyEvent }) => {
    let questions = state.entities[state.section.id].surveyWrapperState.questions;
    // Some sections may pass an event where the index is not 0.
    // We will replace just the question being edited.
    questions = [
      ...questions.slice(0, surveyEvent.questionIndex),
      surveyEvent.questions[surveyEvent.questions.length === 1 ? 0 : surveyEvent.questionIndex],
      ...questions.slice(surveyEvent.questionIndex + 1),
    ];
    const surveyState = {
      displayErrors: { display: false, errors: [] },
      surveyWrapperState: {
        ...state.entities[state.section.id].surveyWrapperState,
        questions,
        tempRequiredDocumentIds: [...getRequiredDocumentIds(surveyEvent.questions)],
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...surveyState },
        id: state.section.id,
      },
      { ...state, loading: false, section: { ...state.section, isDirty: true } },
    );
  }),

  on(SurveySectionActions.updateSurveyWrapperState, (state, { surveyEvent }) => {
    // Survey wrapper update events pass all questions to surveyEvent.questions
    const requiredDocumentIds: number[] = getRequiredDocumentIds(surveyEvent.questions);
    // Update the survey wrapper state. Immutably updates questions via slice.
    // Take the zeroth through the question index (exclusive), put in the updated question and then the rest.
    const surveyState = {
      displayErrors: { display: false, errors: [] },
      surveyWrapperState: {
        ...state.entities[state.section.id].surveyWrapperState,
        questions: [...surveyEvent.questions],
        requiredDocumentIds: [...requiredDocumentIds],
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...surveyState },
        id: state.section.id,
      },
      { ...state, loading: false },
    );
  }),

  on(SurveySectionActions.updateDocChangeRequiredDocIds, (state, { newUploadItem, oldUploadItem }) => {
    const oldRequiredDocumentIds = state.entities[state.section.id].surveyWrapperState.requiredDocumentIds;
    const docIdIndex = oldRequiredDocumentIds.findIndex(id => id === oldUploadItem.documentTypeId);
    const requiredDocumentIds = [
      ...oldRequiredDocumentIds.slice(0, docIdIndex),
      newUploadItem.documentTypeId,
      ...oldRequiredDocumentIds.slice(docIdIndex + 1, oldRequiredDocumentIds.length),
    ];

    const surveyState = {
      surveyWrapperState: {
        ...state.entities[state.section.id].surveyWrapperState,
        requiredDocumentIds: [...requiredDocumentIds],
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...surveyState },
        id: state.section.id,
      },
      { ...state, loading: false },
    );
  }),

  on(SurveySectionActions.updateDocChangeQuestionAnswers, (state, { newUploadItem, oldUploadItem }) => {
    const questionWithDocsIndex = state.entities[state.section.id].surveyWrapperState.questions.findIndex(
      question => question.totalPoints > 0,
    );

    const oldQuestionAnswers = state.entities[state.section.id].surveyWrapperState.questions[questionWithDocsIndex].answers;
    const answerIndex = oldQuestionAnswers.findIndex(id => id === oldUploadItem.documentTypeId.toString());
    const answers = [
      ...oldQuestionAnswers.slice(0, answerIndex),
      newUploadItem.documentTypeId.toString(),
      ...oldQuestionAnswers.slice(answerIndex + 1, oldQuestionAnswers.length),
    ];

    const newQuestions = [
      ...state.entities[state.section.id].surveyWrapperState.questions.slice(0, questionWithDocsIndex),
      { ...new QuestionImpl(state.entities[state.section.id].surveyWrapperState.questions[questionWithDocsIndex]), answers },
      ...state.entities[state.section.id].surveyWrapperState.questions.slice(questionWithDocsIndex + 1, oldQuestionAnswers.length),
    ];

    const surveyState = {
      surveyWrapperState: {
        ...state.entities[state.section.id].surveyWrapperState,
        questions: newQuestions,
      },
    };

    return surveySectionAdapter.updateOne(
      {
        changes: { ...surveyState },
        id: state.section.id,
      },
      { ...state, loading: false },
    );
  }),

  on(SurveySectionActions.updateUploadItem, (state, { uploadItem, uploadsStatus }) => {
    const uploads = state.entities[state.section.id]?.uploadItems;
    const uploadItemIndex = uploads.findIndex(item => item.id === uploadItem.id);
    const uploadItems = [...uploads.slice(0, uploadItemIndex), uploadItem, ...uploads.slice(uploadItemIndex + 1, uploads.length)];
    const incomplete = uploadsStatus === 'completed' && uploadItems.some(item => !item.uploaded);

    return surveySectionAdapter.updateOne(
      {
        changes: {
          displayErrors: {
            display: incomplete,
            errors: incomplete ? [...state.entities[state.section.id].displayErrors.errors] : [],
          },
          uploadItems,
        },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.replaceUploadItem, (state, { newUploadItem, oldUploadItem }) => {
    const uploads = state.entities[state.section.id]?.uploadItems;
    const uploadItems = createNewUploadItems(newUploadItem, oldUploadItem, uploads);

    return surveySectionAdapter.updateOne(
      {
        changes: {
          uploadItems,
        },
        id: state.section.id,
      },
      state,
    );
  }),

  on(SurveySectionActions.setLoading, (state, { loading }) => {
    return {
      ...state,
      loading,
    };
  }),
);

export function surveySectionReducer(state: SurveySection = initialSurveySectionState, action: Action) {
  return workflowReducer(state, action);
}

export const {
  // leaving these as examples, but we won't need these
  selectIds: selectCountyIds,
  selectEntities: selectCountyEntities,
  selectAll: selectAllCounties,
  selectTotal: selectCountyTotal,
} = surveySectionAdapter.getSelectors();
