import { DocumentTypes, Question, QuestionImpl, UploadItem } from '@dmv/public/shared/http';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromSurveySection from '../reducers/survey-section.reducer';

export const selectSurveySectionState = createFeatureSelector<fromSurveySection.SurveySection>(
  fromSurveySection.SURVEY_SECTION_FEATURE_KEY,
);

export const selectSurveySection = createSelector(selectSurveySectionState, state => state?.section);

export const selectSurveySectionStatus = createSelector(selectSurveySection, section => section?.status);

export const selectSurveySectionIsDirty = createSelector(selectSurveySection, section => section?.isDirty || false);

export const selectSectionDescription = createSelector(selectSurveySection, section => section?.description || '');

export const selectSectionStepDescription = (step: number) => {
  return createSelector(selectSurveySection, section => {
    return section?.steps[step]?.description || '';
  });
};

export const selectActiveStep = createSelector(selectSurveySectionState, state => state.activeStep);

export const selectSurveyEntity = (sectionId: string) => createSelector(selectSurveySectionState, state => state.entities[sectionId]);

export const selectCurrentSurveyEntity = createSelector(selectSurveySectionState, state => state.entities[state.section.id]);

export const selectSurveyAnswers = createSelector(selectCurrentSurveyEntity, state => state.answers);

export const selectDisplayErrors = createSelector(selectCurrentSurveyEntity, state => state.displayErrors);

export const selectSectionIsReviewed = createSelector(selectCurrentSurveyEntity, surveyWrapperState => surveyWrapperState.isReviewed);

export const selectSectionId = createSelector(selectCurrentSurveyEntity, state => state.sectionId);

export const selectSectionReviewObjects = createSelector(selectCurrentSurveyEntity, surveyWrapperState => surveyWrapperState.sectionReview);

export const selectSurveyWrapperState = createSelector(selectCurrentSurveyEntity, state => state.surveyWrapperState);

export const selectSectionUploadItems = createSelector(selectCurrentSurveyEntity, surveyWrapperState =>
  surveyWrapperState?.uploadItems.map((uploadItem: UploadItem) => new UploadItem(uploadItem)),
);

export const selectSectionQuestions = createSelector(selectSurveyWrapperState, surveyWrapperState =>
  surveyWrapperState.questions.map((question: Question) => new QuestionImpl(question)),
);

export const selectSurveySectionQuestionIndex = createSelector(
  selectSurveyWrapperState,
  surveyWrapperState => surveyWrapperState.questionIndex,
);

export const selectSurveySectionQuestions = createSelector(selectSurveyWrapperState, surveyWrapperState => surveyWrapperState.questions);

export const selectSurveyQuestionById = (questionId: string) =>
  createSelector(selectSurveySectionQuestions, questions => questions.find(question => question.id === questionId));

export const selectSurveyQuestionAnswersById = (questionId: string) =>
  createSelector(selectSurveyQuestionById(questionId), question => question?.answers || []);

export const selectFromOverallAnswersByQuestionId = (questionId: string) =>
  createSelector(selectCurrentSurveyEntity, state => state.answers[questionId]);

export const selectDocumentTypes = createSelector(selectSurveySectionState, state => state.documentTypes);
export const selectTransactionUri = createSelector(selectSurveySectionState, state => state.transactionUri);

export const selectDocumentUploadContext = createSelector(
  selectSurveyWrapperState,
  selectDocumentTypes,
  (surveyWrapperState, sectionDocumentTypes: DocumentTypes[]) => {
    const reqDocIds = surveyWrapperState.requiredDocumentIds;

    return sectionDocumentTypes?.filter(docType => reqDocIds.includes(+docType.id)) || [];
  },
);

export const selectUsedDocumentTypes = createSelector(selectSurveySectionState, state => state.usedDocumentTypes);

export const selectIsLoading = createSelector(selectSurveySectionState, state => state.loading);
