import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as CountyActions from '../actions/county.actions';
import { CountyState } from '../reducers/county.reducer';
import * as CountySelectors from '../selectors/county.selectors';

@Injectable({
  providedIn: 'root',
})
export class CountyFacade {
  public counties$ = this._store.select(CountySelectors.getCounties);
  public countyOffices$ = this._store.select(CountySelectors.selectCountyOffices);
  public jurisdictions$ = this._store.select(CountySelectors.selectJurisdictions);

  constructor(private readonly _store: Store<CountyState>) {}

  public loadCounties(): void {
    this._store.dispatch(CountyActions.loadCounties());
  }

  public loadJurisdictions(): void {
    this._store.dispatch(CountyActions.loadJurisdictions());
  }

  public isStateRunCounty(countyName: string): Observable<boolean> {
    return this._store
      .select(CountySelectors.getCounties)
      .pipe(
        map(counties => counties.some(county => county.stateRun && county.name?.toLocaleLowerCase() === countyName?.toLocaleLowerCase())),
      );
  }
}
