import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { take } from 'rxjs/operators';
import { ConfirmationCounty, ConfirmationModal } from './confirmation-modal.model';

interface Payload {
  confirmed: boolean;
  countyId?: number;
  officeName?: string;
  payOnline?: boolean;
  isPrescreen?: boolean;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatDividerModule, MatSelectModule],
  selector: 'dmv-confirmation-modal',
  standalone: true,
  styleUrls: ['./confirmation-modal.component.scss'],
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent implements OnInit {
  public countyId?: number;
  public countyName?: string;
  public isPrescreen = false;
  public showButtons = true;
  public showPrescreenQuestion = false;

  private _confirmed = true;
  constructor(
    private readonly _dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationModal,
  ) {}

  @HostListener('window:keydown.escape') public onKeyDown() {
    this.closeDialog({ confirmed: false });
  }

  public setCounty(county?: ConfirmationCounty) {
    this.countyId = county?.id;

    this.showPrescreenQuestion = Boolean(
      county?.metadata?.find(meta => meta?.transactionType?.type === this.data.transactionType)?.enabled,
    );

    if (county?.id) {
      this._confirmed = true;
      this.showButtons = true;
    } else {
      this._confirmed = false;
      this.showButtons = false;
    }
  }

  public setCountyName(name: string) {
    this.countyName = name;
  }

  public onCancelClick() {
    // This because we need the cancel button to act differently than
    // closing the window with the X button in some instances
    this.closeDialog({ confirmed: false });
  }

  public onConfirmClick() {
    // Additional variables will be null for transactions that don't need them
    this.closeDialog({
      confirmed: this._confirmed,
      countyId: this.countyId,
      isPrescreen: this.showPrescreenQuestion ? this.isPrescreen : undefined,
    });
  }

  public ngOnInit(): void {
    // Hide buttons initially if county list is provided because it's required
    if (this.data.countyList) {
      this.showButtons = false;
    }

    this._dialogRef
      .backdropClick()
      .pipe(take(1))
      .subscribe(() => {
        this.closeDialog({ confirmed: false });
      });
  }

  public closeDialog(payload: Payload) {
    this._dialogRef.close(payload);
  }
}
