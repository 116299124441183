import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AnswerEvent,
  BottomNavigation,
  MultiUploadPanelsEvent,
  Question,
  StepType,
  SurveySectionType,
  TransactionType,
  UploadItem,
} from '@dmv/public/shared/http';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'dmv-transaction-section',
  templateUrl: './transaction-section.component.html',
})
export class TransactionSectionComponent {
  @Input() public loading = false;
  @Input() public currentSection: SurveySectionType;
  @Input() public displayErrors: { display: boolean; errors: string[] };
  @Input() public sectionHeading?: string | null = null;
  @Input() public surveyData: { activeStep: StepType; questions: Question[] };
  @Input() public stepConfiguration: BottomNavigation | null = null;
  @Input() public transactionId: number | null = null;
  @Input() public transactionType: TransactionType;
  @Input() public uploadDocuments: UploadItem[];
  @Output() public readonly continueClick: EventEmitter<StepType> = new EventEmitter<StepType>();
  @Output() public readonly updateUploadItem: EventEmitter<MultiUploadPanelsEvent> = new EventEmitter<MultiUploadPanelsEvent>();
  @Output() public readonly reviewedUploads: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public readonly submit: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly surveyAnswer: EventEmitter<AnswerEvent> = new EventEmitter<AnswerEvent>();
  @Output() public readonly resetSection: EventEmitter<{ activeStep: StepType; currentSection: SurveySectionType }> = new EventEmitter<{
    activeStep: StepType;
    currentSection: SurveySectionType;
  }>();

  public onContinueClick(activeStep: StepType): void {
    this.continueClick.emit(activeStep);
  }

  public onUploadItem($event: MultiUploadPanelsEvent): void {
    this.updateUploadItem.emit($event);
  }

  public onPreviousClick(activeStep: StepType): void {
    // Always reset back to initial step on previous click
    this._resetSection(activeStep);
  }

  public onReviewedUploads($event: boolean): void {
    this.reviewedUploads.emit($event);
  }

  public onSubmitClick(): void {
    this.submit.emit();
  }

  public onSurveyAnswerEvent($event: AnswerEvent, _step?: number) {
    this.surveyAnswer.emit($event);
  }

  private _resetSection(activeStep: StepType) {
    this.resetSection.emit({ activeStep, currentSection: this.currentSection });
  }
}
