<div class="d-flex flex-column justify-content-center" tabindex="-1" dmvFocus="true">
  <div
    class="container current-license-container col-12 col-sm-12 col-lg-8"
    tabindex="-1"
    dmvFocus="true"
    focusEventName="dmv-focus-main-content-event"
  >
    <h1>{{ sectionHeading }}</h1>
    <section *ngIf="displayErrors.display">
      <dmv-toast [setFocus]="displayErrors.display" [show]="displayErrors.display" showIcon="true" type="error" heading="Error:">
        <ol class="toast-content-list toast-content-list-error" data-testid="dmvValidationErrorMessage">
          <li *ngFor="let error of displayErrors.errors" [innerHTML]="error" data-testid="error-item"></li>
        </ol>
      </dmv-toast>
    </section>
    <ng-container [ngSwitch]="surveyData.activeStep">
      <ng-container *ngSwitchCase="'survey'">
        <dmv-survey
          *ngIf="surveyData.questions"
          [question]="surveyData.questions[0]"
          [displayAnswer]="true"
          (inputBlur)="onSurveyAnswerEvent($event, 0)"
        >
        </dmv-survey>
      </ng-container>
      <ng-container *ngSwitchCase="'document_upload'">
        <section class="document-upload">
          <dmv-shared-feature-upload-documents
            [existingUploadItems]="uploadDocuments"
            [transactionId]="transactionId"
            [transactionType]="transactionType"
            (uploadEvent)="onUploadItem($event)"
          >
          </dmv-shared-feature-upload-documents>
        </section>
      </ng-container>
      <ng-container *ngSwitchCase="'form'">
        <ng-content select="[formContent]"></ng-content>
      </ng-container>
      <ng-container *ngSwitchCase="'review'">
        <ng-content select="[reviewContent]"></ng-content>
      </ng-container>
    </ng-container>
    <div class="footer-container">
      <dmv-footer></dmv-footer>
    </div>
  </div>

  <div class="bottom-navigation-container">
    <dmv-bottom-navigation
      *ngIf="stepConfiguration"
      [continueText]="stepConfiguration.continueText"
      [previousText]="stepConfiguration.previousText"
      [showContinue]="stepConfiguration.showContinue"
      [showPrevious]="stepConfiguration.showPrevious"
      [showSubmit]="stepConfiguration.showSubmit"
      [submitDisabled]="loading"
      [submitText]="stepConfiguration.submitText"
      (continueClick)="onContinueClick(surveyData.activeStep)"
      (previousClick)="onPreviousClick(surveyData.activeStep)"
      (submitClick)="onSubmitClick()"
    ></dmv-bottom-navigation>
  </div>
</div>
